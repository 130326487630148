// import React from "react";
// import styled from "styled-components";
// import tw from "twin.macro";
// //eslint-disable-next-line
// import { css } from "styled-components/macro";
// import { SectionHeading } from "components/misc/Headings.js";

// import defaultCardImage from "../../../images/shield-icon.svg";

// import { ReactComponent as SvgDecoratorBlob3 } from "../../../images/svg-decorator-blob-3.svg";


// import Interior from "../../../images/interior-logo.svg";
// import Website from "../../../images/website-icon.svg";
// import Plumbing from "../../../images/plumbing-icon.svg";
// import Painting from "../../../images/painting-icon.svg";
// import Electrical from "../../../images/electrical-icon.svg";
// import Stationary from "../../../images/stationary-icon.svg";
// import Transport from "../../../images/transport-icon.svg";
// import Technical from "../../../images/technical.svg";
// import Cleaning from "../../../images/housekeeping.svg";
// import Carpenter from "../../../images/carpenter.svg";
// import Pest from "../../../images/pest.svg";

// import SupportIconImage from "../../../images/manpower.svg";
// import ShieldIconImage from "../../../images/shield-icon.svg";
// import CustomizeIconImage from "../../../images/customize-icon.svg";
// import FastIconImage from "../../../images/fast-icon.svg";
// import ReliableIconImage from "../../../images/reliable-icon.svg";
// import SimpleIconImage from "../../../images/simple-icon.svg";
// import { Link } from "react-router-dom";
// import { BackButton, CloseButton, PrimaryButtonHome as PrimaryButtonHomeBase } from "components/misc/Buttons.js";

// const Container = tw.div`relative`;
// const Links = styled(PrimaryButtonHomeBase).attrs({as: "a"})`
//   ${tw`inline-block mt-4 text-sm font-semibold`}
// `
// const ThreeColumnContainer = styled.div`
//   ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
// `;
// const Heading = tw(SectionHeading)`w-full`;

// const Column = styled.div`
//   ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
// `;

// const Card = styled.div`
//   ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12 hover:scale-105 cursor-pointer`}
//   .imageContainer {
//     ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
//     img {
//       ${tw`w-8 h-8`}
//     }
//   }

//   .textContainer {
//     ${tw`mt-6 text-center`}
//   }

//   .title {
//     ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
//   }

//   .description {
//     ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
//   }
// `;

// const DecoratorBlob = styled(SvgDecoratorBlob3)`
//   ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
// `;

// export default () => {
//   /*
//    * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
//    *  1) imageSrc - the image shown at the top of the card
//    *  2) title - the title of the card
//    *  3) description - the description of the card
//    *  If a key for a particular card is not provided, a default value is used
//    */

//   const cards = [
    
   
//     {
//       imageSrc: Carpenter,
//       title: "Carpentry Services ",
//       description: " This includes the construction, repair, and installation of structures made of wood or other materials, such as cabinets, shelves, and furniture "
//     },
//     {
//       imageSrc: Pest,
//       title: "Pest Control Services ",
//       description: " This includes the removal and prevention of pests, such as insects, rodents, and other unwanted animals."
//     },
//     {
//       imageSrc: Electrical,
//       title: "Flat relocation Services ",
//       description: " Flat moving services, refer to the professional assistance provided in relocating from one flat or apartment to another. "
//     },
//     {
//       imageSrc: Electrical,
//       title: "Pop work Services ",
//       description: " service involves the installation of decorative and functional elements made of Plaster of Paris "
//     },
//     {
//       imageSrc: Painting,
//       title: "Painting contracts",
//       description: " We Do All type of Industrial painting works from Machinery Paintings to All Walls and other Painting Work  With Quality paints & Assurance"
//     },
//     { imageSrc: SupportIconImage, 
//       title: "AC Services",
//       description: " Our AC Services are designed to provide our clients with high-quality, reliable, and affordable AC installation, repair, and maintenance solutions. "
//     },
//     {
//       imageSrc: Plumbing,
//       title: "Plumbing Contracts",
//       description: " We Do All types of Plumbing works from New Installation to existing Work Maintaince  With Material "
//     },
    
//     { imageSrc: SupportIconImage, 
//       title: "Sliding Window",
//       description: " Our Sliding Windows Services are designed to provide our clients with high-quality, reliable, and affordable sliding window installation, repair, and maintenance solutions. "
//     },
//     {
//       imageSrc: Interior,
//       title: "Interior & Furniture renovation. ",
//       description: " We Do Interior as well Furniture , Sliding Doors and POP works With Fastest And Quality Work ."
//     }
//   ];

//   return (
//     <Container>
//       <ThreeColumnContainer>
//         <Heading>Our Professional <span tw="text-orange-500">Home Services</span></Heading>
//         {cards.map((card, i) => (
//           <Column key={i}> 
//             <Link to="/contact">
//             <Card>
//               <span className="imageContainer">
//                 <img src={card.imageSrc || defaultCardImage} alt="" />
//               </span>
//               <span className="textContainer">
//                 <span className="title">{card.title || "Fully Secure"}</span>
//                 <p className="description">
//                   {card.description || "."}
//                 </p>
//               </span>
//               <Links target="_blank">Contact</Links>

//             </Card>
//             </Link>
//           </Column>
//         ))}
        
//       </ThreeColumnContainer>
//       <DecoratorBlob />
//     </Container>
//   );
// };
import React, { useState } from "react";
import Slider from "react-slick";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons";
import { ReactComponent as PriceIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import { ReactComponent as LocationIcon } from "feather-icons/dist/icons/map-pin.svg";
import { ReactComponent as StarIcon } from "feather-icons/dist/icons/star.svg";
import { ReactComponent as ChevronLeftIcon } from "feather-icons/dist/icons/chevron-left.svg";
import { ReactComponent as ChevronRightIcon } from "feather-icons/dist/icons/chevron-right.svg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20`;

const HeadingWithControl = tw.div`flex flex-col items-center sm:items-stretch sm:flex-row justify-between`;
const Heading = tw(SectionHeading)``;
const Controls = tw.div`flex items-center`;
const ControlButton = styled(PrimaryButtonBase)`
  ${tw`mt-4 sm:mt-0 first:ml-0 ml-6 rounded-full p-2`}
  svg {
    ${tw`w-6 h-6`}
  }
`;
const Span = tw.span`text-ray-900`
const PrevButton = tw(ControlButton)``;
const NextButton = tw(ControlButton)``;

const CardSlider = styled(Slider)`
  ${tw`mt-16`}
  .slick-track { 
    ${tw`flex`}
  }
  .slick-slide {
    ${tw`h-auto flex justify-center mb-1`}
  }
`;
const Card = tw.div`shadow-lg h-full flex! flex-col sm:border max-w-sm sm:rounded-tl-4xl sm:rounded-br-5xl relative focus:outline-none p-10`;
const CardImage = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`w-full h-56 sm:h-64 bg-cover bg-center rounded sm:rounded-none sm:rounded-tl-4xl`
]);

const TextInfo = tw.div`py-6 sm:px-10 sm:py-6`;
const TitleReviewContainer = tw.div`flex flex-col sm:flex-row sm:justify-between sm:items-center`;
const Title = tw.h5`text-2xl font-bold`;

const RatingsInfo = styled.div`
  ${tw`flex items-center sm:ml-4 mt-2 sm:mt-0`}
  svg {
    ${tw`w-6 h-6 text-yellow-500 fill-current`}
  }
`;
const Rating = tw.span`ml-2 font-bold`;

const Description = tw.p`text-sm leading-loose mt-2 sm:mt-4`;

const SecondaryInfoContainer = tw.div`flex flex-col sm:flex-row mt-2 sm:mt-4`;
const IconWithText = tw.div`flex items-center mr-6 my-2 sm:my-0`;
const IconContainer = styled.div`
  ${tw`inline-block rounded-full p-2 bg-gray-700 text-gray-100`}
  svg {
    ${tw`w-3 h-3`}
  }
`;
const Text = tw.div`ml-2 text-sm font-semibold text-gray-800`;

const PrimaryButton = tw(PrimaryButtonBase)`mt-auto sm:text-lg rounded-none w-full rounded sm:rounded-none sm:rounded-br-4xl py-3 sm:py-6`;
export default () => {
  // useState is used instead of useRef below because we want to re-render when sliderRef becomes available (not null)
  const [sliderRef, setSliderRef] = useState(null);
  const sliderSettings = {
    arrows: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        }
      },

      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
        }
      },
    ]
  };

  /* Change this according to your needs */
  const cards = [
    {
      imageSrc: "https://images.unsplash.com/photo-1618220179428-22790b461013?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=327&q=80",
      title: "Carpentry Service",
      description: " This includes the construction, repair, and installation of structures made of wood or other materials, such as cabinets, shelves, and furniture ",
      locationText: "Pune, India",
    },
    {
      imageSrc: "https://images.unsplash.com/photo-1581578731548-c64695cc6952?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
      title: "Pest Control Services ",
      description: " This includes the removal and prevention of pests, such as insects, rodents, and other unwanted animals.",
   locationText: "Pune, India",
    },
    {
      imageSrc: "https://images.unsplash.com/photo-1612630741022-b29ec17d013d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80",
      title: "Flat relocation Services ",
      description: " Flat moving services, refer to the professional assistance provided in relocating from one flat or apartment to another. ",   
      locationText: "Pune, India",

    },
    {
      imageSrc: "https://img.freepik.com/premium-photo/young-asian-worker-as-painter-paint-walls-house-use-roller-paint-white-primer-construction-site_140555-887.jpg",
      title: "Painting contracts",
      description: " We Do All type of Industrial painting works from Machinery Paintings to All Walls and other Painting Work  With Quality paints & Assurance",
      locationText: "Pune, India",

    },
    {
      imageSrc: "https://content.jdmagicbox.com/comp/pune/w4/020pxx20.xx20.191103121111.n4w4/catalogue/ar-airconditiong-dehu-pune-ac-installation-services-fv546jp1rx.jpg",
      title: "AC Services",
      description: " Our AC Services are designed to provide our clients with high-quality, reliable, and affordable AC installation, repair, and maintenance solutions. ",
      locationText: "Pune, India",

    },
    {
      imageSrc: "https://api.gharpedia.com/wp-content/uploads/2018/08/0602030005-01-Plumbers.jpg",
      title: "Plumbing Contracts",
       description: " We Do All types of Plumbing works from New Installation to existing Work Maintaince  With Material ",
       locationText: "Pune, India",

    },
    {
      imageSrc: "https://images.unsplash.com/photo-1615529182904-14819c35db37?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bGl2aW5nJTIwcm9vbSUyMGludGVyaW9yJTIwZGVzaWdufGVufDB8fDB8fA%3D%3D&w=1000&q=80",
      title: "Interior & Furniture renovation. ",
            description: " We Do Interior as well Furniture , Sliding Doors and POP works With Fastest And Quality Work .",
            locationText: "Pune, India",

    },
    // {
    //   imageSrc: "https://images.unsplash.com/photo-1571770095004-6b61b1cf308a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&h=1024&w=768&q=80",
    //   title: "Hudak Homes",
    //   description: "Lorem ipsum dolor sit amet, consectur dolori adipiscing elit, sed do eiusmod tempor nova incididunt ut labore et dolore magna aliqua.",
    //   locationText: "Arizona, RAK",
    //   pricingText: "USD 99/Day",
    //   rating: 4.5,
    // },
  ]

  return (
    <Container>
      <Content>
        <HeadingWithControl>
          <Heading><Span><span>Home</span></Span> Services</Heading>
          {/* <Controls>
            <PrevButton onClick={sliderRef?.slickPrev}><ChevronLeftIcon/></PrevButton>
            <NextButton onClick={sliderRef?.slickNext}><ChevronRightIcon/></NextButton>
          </Controls> */}
        </HeadingWithControl>
        {/* <CardSlider ref={setSliderRef} {...sliderSettings}> */}
        <div class="row gx-5">
    
          {cards.map((card, index) => (
            <div class="col p-2" key={index}>
            <Card >
              
              <CardImage imageSrc={card.imageSrc} />
              <TextInfo>
                <TitleReviewContainer>
                  <Title>{card.title}</Title>
                  {/* <RatingsInfo>
                    <StarIcon />
                    <Rating>{card.rating}</Rating>
                  </RatingsInfo> */}
                </TitleReviewContainer>
                <SecondaryInfoContainer>
                  <IconWithText>
                    <IconContainer>
                      <LocationIcon />
                    </IconContainer>
                    <Text>{card.locationText}</Text>
                  </IconWithText>
                  {/* <IconWithText>
                    <IconContainer>
                      <PriceIcon />
                    </IconContainer>
                    <Text>{card.pricingText}</Text>
                  </IconWithText> */}
                </SecondaryInfoContainer>
                <Description>{card.description}</Description>
              </TextInfo>
              <PrimaryButton>Book Now</PrimaryButton>
             
            </Card>
            </div>
          ))}
          </div>
        {/* </CardSlider> */}
        
      </Content>
    </Container>
  );
};
