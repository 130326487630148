import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

import defaultCardImage from "../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../images/svg-decorator-blob-3.svg";

import SupportIconImage from "../../images/housekeeping.svg";
import ShieldIconImage from "../../images/transport-icon.svg";
import CustomizeIconImage from "../../images/manpower.svg";
import FastIconImage from "../../images/fast-icon.svg";
import ReliableIconImage from "../../images/reliable-icon.svg";
import SimpleIconImage from "../../images/simple-icon.svg";
import { Link } from "react-router-dom";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;


const PrimaryButton = tw(PrimaryButtonBase)`mt-8 md:mt-10 text-sm inline-block mx-auto md:mx-0`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    {
      imageSrc: ShieldIconImage,

      title: "Transport Contracts",
      description: "We Provide Transport Facility with Permit Vehicles . Passenger as well as For Material and equipment Movements"
    },
    {
      imageSrc:CustomizeIconImage,
      title: "Staffing, Outsourcing and Manpower Supply",
      description: "We Provide Industry Standart Work Force to fullfill business Requirement with excellence."
    },
    { imageSrc: SupportIconImage, title: "Housekeeping Contract",
    description:"- We Provide All type of Housekeeping Staff and Whole Contract with Material many more Facilities"

  },
  //   { imageSrc: CustomizeIconImage, title: "Stationary Supply",
  // description:"We Provide All type of office/industrial Stationary at wholesale Market Rate  with Quality Brands . ( GSTIN  ,  Material Rate List  , free Transportation ) " },
  //   { imageSrc: ReliableIconImage, title: "Transport Contracts ",
  // description:"We Provide Transport Facility with Permit Vehicles . Passenger as well as For Material and equipment Movements.  " },
  //   { imageSrc: FastIconImage, title: "Electrical Contract" ,
  // description:" We Do All type Industrial As well other Electrical Works from new Installations to maintenance With Guarantee and Quality . "},
  //   { imageSrc: SimpleIconImage, title: "Maintenance contract",description:"We Do All types of Machinery as well other maintains work as per your requirement with Expert and Priority . " }

  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading><span tw="text-ray-500">Our Professional</span> <span tw="text-orange-500">Services</span></Heading>

        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Sic Semper Tyrannis. Neoas Calie artel."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
        
        <Link to="/services">
        <PrimaryButton >
              More Services
            </PrimaryButton>
            </Link>
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
