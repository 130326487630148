import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "../../../images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "../../../images/svg-decorator-blob-3.svg";


import Interior from "../../../images/interior-logo.svg";
import Website from "../../../images/website-icon.svg";
import Plumbing from "../../../images/plumbing-icon.svg";
import Painting from "../../../images/painting-icon.svg";
import Electrical from "../../../images/electrical-icon.svg";
import Stationary from "../../../images/stationary-icon.svg";
import Transport from "../../../images/transport-icon.svg";
import Technical from "../../../images/technical.svg";
import Cleaning from "../../../images/housekeeping.svg";
import Carpenter from "../../../images/carpenter.svg";
import Pest from "../../../images/pest.svg";

import SupportIconImage from "../../../images/manpower.svg";
import ShieldIconImage from "../../../images/shield-icon.svg";
import CustomizeIconImage from "../../../images/customize-icon.svg";
import FastIconImage from "../../../images/fast-icon.svg";
import ReliableIconImage from "../../../images/reliable-icon.svg";
import SimpleIconImage from "../../../images/simple-icon.svg";
import { Link } from "react-router-dom";
import { BackButton, CloseButton, PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

const Container = tw.div`relative`;
const Links = styled(PrimaryButtonBase).attrs({as: "a"})`
  ${tw`inline-block mt-4 text-sm font-semibold`}
`
const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-xl mx-auto py-20 md:py-24`}
`;
const Heading = tw(SectionHeading)`w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 px-6 flex`}
`;

const Card = styled.div`
  ${tw`flex flex-col mx-auto max-w-xs items-center px-6 py-10 border-2 border-dashed border-primary-500 rounded-lg mt-12 hover:scale-105 cursor-pointer`}
  .imageContainer {
    ${tw`border-2 border-primary-500 text-center rounded-full p-6 flex-shrink-0 relative`}
    img {
      ${tw`w-8 h-8`}
    }
  }

  .textContainer {
    ${tw`mt-6 text-center`}
  }

  .title {
    ${tw`mt-2 font-bold text-xl leading-none text-primary-500`}
  }

  .description {
    ${tw`mt-3 font-semibold text-secondary-100 text-sm leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default () => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component):
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const cards = [
    
    { imageSrc: SupportIconImage, 
      title: "ManPower Supply ",
      description: "We Provide All types of Manpower as per Requirement from Technical to Non-Technical "
    },
    {
      imageSrc: Technical,
      title: "Technical Contracts ",
      description: "CNC/VMC Machining’s, Milling Machine, Lathe Machine,  , Fettling , Drilling  Sandblasting, Powder coating etc  "
    },
    { imageSrc: Cleaning, 
      title: "Housekeeping Contract",
      description: " We Provide All type of Housekeeping Staff and Whole Contract with Material many more Facilities. "
    },
    { imageSrc: SupportIconImage, 
      title: "IT Services",
      description: " Our IT services are designed to provide our clients with comprehensive technical support, assistance, and solutions to help them achieve their business goals. We understand that technology is critical to the success of modern businesses. "
    },
    { imageSrc: Stationary, 
      title: "Stationary supply",
      description: " -  We Provide All type of office/industrial Stationary at wholesale Market Rate  with Quality Brands . ( GSTIN  ,  Material Rate List  , free Transportation "
    },
    { imageSrc: Transport,
      title: "Transport Contracts ",
      description: " We Provide Transport Facility with Permit Vehicles . Passenger as well as For Material and equipment Movements"
    },
    { imageSrc: SupportIconImage, 
      title: "BPO",
      description: "Our BPO services are to help our clients improve their operational and reduce costs by outsourcing non-core business processes to our experienced and skilled team "
    },
    {
      imageSrc: Electrical,
      title: "Electrical Contract ",
      description: " We Do All type Industrial As well other Electrical Works from new Installations to maintenance With Guarantee and Quality "
    },
    {
      imageSrc: Carpenter,
      title: "Carpentry Services ",
      description: " This includes the construction, repair, and installation of structures made of wood or other materials, such as cabinets, shelves, and furniture "
    },
    {
      imageSrc: Pest,
      title: "Pest Control Services ",
      description: " This includes the removal and prevention of pests, such as insects, rodents, and other unwanted animals."
    },
    {
      imageSrc: Electrical,
      title: "Flat relocation Services ",
      description: " Flat moving services, refer to the professional assistance provided in relocating from one flat or apartment to another. "
    },
    {
      imageSrc: Electrical,
      title: "Pop work Services ",
      description: " service involves the installation of decorative and functional elements made of Plaster of Paris "
    },
    {
      imageSrc: Painting,
      title: "Painting contracts",
      description: " We Do All type of Industrial painting works from Machinery Paintings to All Walls and other Painting Work  With Quality paints & Assurance"
    },
    { imageSrc: SupportIconImage, 
      title: "AC Services",
      description: " Our AC Services are designed to provide our clients with high-quality, reliable, and affordable AC installation, repair, and maintenance solutions. "
    },
    {
      imageSrc: Plumbing,
      title: "Plumbing Contracts",
      description: " We Do All types of Plumbing works from New Installation to existing Work Maintaince  With Material "
    },
    { imageSrc: SupportIconImage, 
      title: "Gardening Contract",
      description: " We Provide Gardening maintaince facility  OR Gardening Staff for Cleanness with All material and equipment "
    },
    { imageSrc: SupportIconImage, 
      title: "Sliding Window",
      description: " Our Sliding Windows Services are designed to provide our clients with high-quality, reliable, and affordable sliding window installation, repair, and maintenance solutions. "
    },
    {
      imageSrc: Website,
      title: "Industrial website creation and social media handles.",
      description: " We Made All types of Industrial Website As well As Social Media Handles and ERP Software Solution  With 24/7 Support  "
    },{
      imageSrc: SupportIconImage,
      title: "Maintenance contract",
      description: " We Do All types of Machinery as well other maintains work as per your requirement with Expert and Priority . "
    },{
      imageSrc: Interior,
      title: "Interior & Furniture Contracting. ",
      description: " We Do Interior as well Furniture , Sliding Doors and POP works With Fastest And Quality Work ."
    },{
      imageSrc: SupportIconImage,
      title: "Civil Works",
      description: " We are pleased to offer our Civil Works services to assist with your construction and infrastructure needs. "
    },{
      imageSrc: Technical,
      title: "Machinery On Rent",
      description: " We are pleased to offer our machinery rental services to assist with your upcoming project needs"
    }
  ];

  return (
    <Container>
      <ThreeColumnContainer>
        <Heading>Our Professional <span tw="text-orange-500">Industry Services</span></Heading>
        {cards.map((card, i) => (
          <Column key={i}> 
            <Link to="/contact">
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "."}
                </p>
              </span>
              <Links target="_blank">Contact</Links>

            </Card>
            </Link>
          </Column>
        ))}
        
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
