import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import { Container as ContainerBase } from "components/misc/Layouts.js"
import logo from "../../images/01.png";

import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";
import { ReactComponent as LinkedIn } from "../../images/linkedin-icon.svg";
import { ReactComponent as Instagram } from "../../images/instagram-icon.svg";

import { Link } from "react-router-dom";

const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const Row = tw.div`flex items-center justify-center flex-col px-8`

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;

const LogoImg = tw.img`w-20`;

const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const LinkssContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`
const Links = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const SocialLinkssContainer = tw.div`mt-10`;
const SocialLinks = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`
export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={logo} />

            <LogoText></LogoText>

            {/* <LogoText>Raydito</LogoText> */}

          </LogoContainer>
          <LinkssContainer>
          
            <Links><Link to={"/"}>Home</Link></Links>
            <Links><Link to={"/about"}>About</Link></Links>
            <Links><Link to={"/contact"}>Contact Us</Link></Links>
            <Links><Link to={"/jobs"}>Jobs</Link></Links>
            <Links><Link to={"/services/home"}> Home Services</Link></Links>
            <Links><Link to={"/services/industry"}> Industry Services</Link></Links>

          </LinkssContainer>
          <SocialLinkssContainer>
            <SocialLinks href="https://www.facebook.com/rayditoservices/">
              <FacebookIcon />
            </SocialLinks>
            <SocialLinks href="https://www.linkedin.com/company/rayditoservices/">
              <LinkedIn/>
            </SocialLinks>
            <SocialLinks href="https://twitter.com/RayditoServices">
              <TwitterIcon />
            </SocialLinks>
            <SocialLinks href="https://www.youtube.com/@RayditoServices">
              <YoutubeIcon />
            </SocialLinks>
            <SocialLinks href="https://www.instagram.com/rayditoservices/">
              <Instagram />
            </SocialLinks>
          </SocialLinkssContainer>
          <CopyrightText>

            &copy; Copyright 2019, Raydito Services. All Rights Reserved.

          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
