import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

import EmailIllustrationSrc from "images/5124556.jpg";
import { useState } from "react";
import { useRef } from "react";
import emailjs from '@emailjs/browser';
import { Navigate, useNavigate } from "react-router-dom";
import ThankYouPage from "ThankYouPage";
// import sgMail from '@sendgrid/mail';
const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-2 md:py-2`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 placeholder-gray-500 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

export default ({
  subheading = "Contact Us",

  heading = <><span tw="text-blue-300">Feel free to </span><span tw="text-primary-500">get in touch</span><wbr/><span tw="text-primary-500">with us.</span> </>,
  description = "Connect us, We will try to communicate with you as soon as possible",

  submitButtonText = "Send",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.


  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact:'',
    message: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const form = useRef();
const[uname,setUname]= useState("");

  function handleChangeName(e){
setUname(e.target.value);
  }

  const navigation = useNavigate();
  const sendEmail = async(e) => {
    e.preventDefault();

    await emailjs.sendForm('service_431k0uu', 'template_r9qg6xw', form.current, 'CeDhcvFkrncNgQaKA')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
localStorage.setItem("uname",uname);
console.log(uname)
    navigation("/thankyou")
setTimeout(() => {
  navigation("/");
}, 6000);
      
  };
  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form action={formAction} method={formMethod} ref={form} onSubmit={sendEmail}>
              <Input type="email" name="user_email" placeholder="Your Email Address" />
              <Input type="text" name="user_name" placeholder="Full Name" onChange={handleChangeName} />
              <Input type="tel" name="user_contact" placeholder="Contact No"  />
              <Textarea name="message"  placeholder="Your Message Here" />
              <SubmitButton type="submit" value="Send">{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
