import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import { SectionHeading as HeadingTitle, Subheading } from "components/misc/Headings.js";
import { BackButton, CloseButton, PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";
import { ReactComponent as TagIcon } from "feather-icons/dist/icons/tag.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../../images/svg-decorator-blob-3.svg";
import { Link } from "react-router-dom";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24 `;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`mt-24 lg:w-1/3`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm flex flex-col h-full`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-cover bg-center h-80 lg:h-64 rounded rounded-b-none`
]);

const Details = tw.div`p-6 rounded border-2 border-t-0 rounded-t-none border-dashed border-primary-500 flex-1 flex flex-col items-center text-center lg:block lg:text-left`;
const MetaContainer = tw.div`flex items-center`;
const Meta = styled.div`
  ${tw`text-secondary-100 font-medium text-sm flex items-center leading-none mr-6 last:mr-0`}
  svg {
    ${tw`w-4 h-4 mr-1`}
  }
`;

const Title = tw.h5`mt-4 leading-snug font-bold text-lg`;
const Description = tw.p`mt-2 text-sm text-secondary-700`;
const Links = styled(PrimaryButtonBase).attrs({as: "a"})`
  ${tw`inline-block mt-4 text-sm font-semibold`}
`

const Back = styled(BackButton).attrs({as: "a"})`
${tw`inline-block mt-4 text-sm font-semibold`}
`

const Close = styled(CloseButton).attrs({as: "a"})`
${tw`inline-block mt-4 text-sm font-semibold`}
`

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;


 export default({
  subheading = "Jobs",
  heading = <>We <span tw="text-orange-500">Hire.</span></>,
  description = "At Raydito, we believe that hiring the right people is key to building a successful and sustainable business. We're committed to creating a work environment that fosters creativity, collaboration, and personal growth for every member of our team.",

}) => {
  const blogPosts = [
    {
      imageSrc:
        "https://media.istockphoto.com/id/868288392/photo/quality-inspectors-team-at-factory.jpg?b=1&s=170667a&w=0&k=20&c=yiZnaQ3_qQEn5bp5YeWw7nvSdmlu7iDjT8S4OZySK78=",
      author: "Pune, Maharashtra",
      category: "Technical;",
      title: "Quality Engineering",
      description: ` The Quality Engineer is responsible for ensuring that products and services meet or exceed customer expectations by developing and implementing quality control procedures, conducting quality audits, and identifying process improvements.`,
      url: "https://raydito.com/jobform"
    },
    {
      imageSrc:
        "https://media.istockphoto.com/id/1271730082/photo/african-worker.jpg?b=1&s=170667a&w=0&k=20&c=onD7OBZfsaof11mfZHqa6BQ37eVfMd_6rSYZIFa67go=",
      author: "Pune, Maharashtra",
      category: "Technical",
      title: "CNC/VMC Operator",
      description: `
      We are looking for an experienced CNC/VMC Operator to join our team. The ideal candidate will be responsible for setting up and operating CNC/VMC machines to produce high-quality parts and components for our manufacturing operations.
      `,
      url: "https://raydito.com/jobform"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1581094481644-f2ab64522498?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8ODR8fGp1bmlvciUyMGVuZ2luZWVyfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
      author: "Pune, Maharashtra",
      category: "Mechanical/Electrical",
      title: "Junior Engineer",
      description: `
      We are looking for a Junior Engineer to join our team and provide support in various engineering projects. The ideal candidate will be a recent engineering graduate or have up to 2 years of relevant experience, with a passion for learning and developing their skills in a dynamic, fast-paced environment.
      `,
      url: "https://raydito.com/jobform"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1504384308090-c894fdcc538d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
      author: "Pune, Maharashtra",
      category: "Technocal;",
      title: "IT Services",
      description: "We are seeking a highly skilled IT Services Specialist to join our team. The successful candidate will be responsible for providing technical support and assistance to our clients and users, ensuring the smooth operation and functionality of our systems and applications.",
      url: "https://raydito.com/jobform"
    },
    {
      imageSrc:
        "https://content.jdmagicbox.com/comp/pune/t2/020pxx20.xx20.221217143713.z3t2/catalogue/k-k-valet-services-kharadi-pune-housekeeping-services-xk21wm12xd.jpg",
      author: "Pune, Maharashtra",
      category: "Any",
      title: "HouseKeeping Person",
      description: "A Housekeeping Person is responsible for ensuring the cleanliness, orderliness, and overall upkeep of indoor spaces such as homes, hotels, offices, and other establishments. The primary responsibilities of a Housekeeping Person include cleaning and tidying rooms, making beds, dusting, vacuuming, and other general housekeeping tasks.",
      url: "https://raydito.com/jobform"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1581092334651-ddf26d9a09d0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OTl8fGp1bmlvciUyMGVuZ2luZWVyfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
      author: "Pune, Maharashtra",
      category: "Mechanical/Electrical",
      title: "Graduate Trainee Engineer",
      description: `
      We are looking for a highly motivated Graduate Trainee Engineer to join our team and undergo a comprehensive training program. The ideal candidate will have recently completed a degree in engineering or a related field, with a passion for learning and developing their skills in a dynamic, fast-paced environment.
      `,
      url: "https://raydito.com/jobform"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1658684016682-85046de74ec3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=594&q=80",
      author: "Pune, Maharashtra",
      category: "Any",
      title: "Sliding window",
      description: "We are seeking a skilled Sliding Window Installer to join our team. The successful candidate will be responsible for installing sliding windows and doors in residential and commercial properties, ensuring that installations are completed accurately and efficiently.",
      url: "https://raydito.com/jobform"
    },
    {
      imageSrc:
        "https://media.istockphoto.com/id/1284938656/photo/a-technician-working-at-factory.jpg?s=612x612&w=0&k=20&c=gqguHQxrntna78W_4Dpd7N_6GMI0Gorsg0EgEM9UeUQ=",
      author: "Pune, Maharashtra",
      category: "Technical",
      title: "Mechanical Fitter",
      description: `
      We are seeking a highly skilled Mechanical Fitter to join our team and provide support in the installation, maintenance, and repair of mechanical systems and equipment. The ideal candidate will have a strong mechanical aptitude and experience working with a variety of tools and equipment.
      `,
      url: "https://raydito.com/jobform"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1612473741370-55a9374e4944?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjB8fHdlbGRlcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
      author: "Pune, Maharashtra",
      category: "Technical",
      title: "Mechanical Welder",
      description: "A Mechanical Welder is responsible for performing welding and metal fabrication duties in accordance with standard welding practices and procedures The primary responsibilities of a Mechanical Welder include interpreting blueprints and welding specifications.",
      url: "https://raydito.com/jobform"
    },
    {
      imageSrc:
        "https://www.kpsol.com/wp-content/uploads/2015/10/call-center-image.jpg",
      author: "Pune, Maharashtra",
      category: "Tech/Non-Tech",
      title: "BPO",
      description: "We are seeking a skilled BPO Services Representative to join our team. The successful candidate will be responsible for providing excellent customer service, handling inbound and outbound calls, and managing customer inquiries and complaints. The role requires a strong focus on meeting customer needs and ensuring customer satisfaction..",
      url: "https://raydito.com/jobform"
    },
    {
      imageSrc:
        "https://plus.unsplash.com/premium_photo-1661764559869-f6052a14b4c9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8Q3VzdG9tZXIlMjBTdXBwb3J0fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
      author: "Pune, Maharashtra",
      category: "Tech/Non-Tech",
      title: "Customer Support Executive",
      description: "A Customer Support Executive is responsible for providing exceptional customer service to clients and customers, resolving issues, and addressing inquiries via various communication channels. The primary responsibility of a Customer Support Executive is to ensure customer satisfaction by providing timely and effective solutions to their problems.",
      url: "https://raydito.com/jobform"
    },
    {
      imageSrc:
        "https://plus.unsplash.com/premium_photo-1661767233586-f1bdd642e8f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDl8fFNhbGVzJTIwUGVyc29ufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
      author: "Pune, Maharashtra",
      category: "Tech/Non-Tech",
      title: "Sales Person",
      description: "A Sales Person is responsible for promoting and selling products or services to customers. The primary responsibility of a Sales Person is to generate revenue by meeting or exceeding sales targets and building strong relationships with customers.      ",
      url: "https://raydito.com/jobform"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1632406898177-95f7acd8854f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTB8fGFkbWlufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
      author: "Pune, Maharashtra",
      category: "Social Media",
      title: "Adminstrative Officer",
      description: "An Administrative Officer is responsible for managing and coordinating various administrative tasks within an organization. The primary responsibilities of an Administrative Officer include maintaining organizational efficiency, managing budgets and expenses, ensuring compliance with company policies, and coordinating various administrative tasks.",
      url: "https://raydito.com/jobform"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1557426272-fc759fdf7a8d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjd8fE9mZmljZSUyME1hbmFnZXJ8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
      author: "Pune, Maharashtra",
      category: "Technical",
      title: "Office Manager",
      description: "An Office Manager is responsible for overseeing the day-to-day operations of an office and ensuring its smooth and efficient functioning. The primary responsibilities of an Office Manager include managing staff, resources, and administrative tasks to ensure that the office operates effectively and efficiently.",
      url: "https://raydito.com/jobform"
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1605067063351-f81bc6041251?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8R3JhcGhpY3MlMjBEZXNpZ25lcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
      author: "Pune, Maharashtra",
      category: "Technical",
      title: "Graphics Designer",
      description: "A Graphics Designer is responsible for creating visual concepts and designs using computer software or by hand to communicate ideas that inspire, inform, or captivate consumers. The primary responsibilities of a Graphics Designer include creating various types of designs, such as logos, brochures, websites, posters, and product packaging, that communicate and promote a company's brand and products or services.",
      url: "https://raydito.com/jobform"
    },
    {
      imageSrc:
        "http://www.ymksecurities.com/images/pantryboy1.jpg",
      author: "Pune, Maharashtra",
      category: "Any",
      title: "Pantry Person",
      description: "A Pantry Person's duties may include preparing and serving coffee, tea, water, and other beverages, setting up and restocking snack and beverage stations, cleaning and organizing the pantry and kitchen areas, washing and sanitizing dishes, and disposing of trash and recyclables.",
      url: "https://raydito.com/jobform"
    },
    {
      imageSrc:
        "https://www.ziprecruiter.com/svc/fotomat/public-ziprecruiter/uploads/job_description_template/Gardener.jpg",
      author: "Pune, Maharashtra",
      category: "Any",
      title: "Gardner",
      description: "A Gardener is responsible for cultivating, maintaining, and enhancing the appearance of outdoor spaces such as gardens, lawns, and parks. The primary responsibilities of a Gardener include planting and caring for flowers, shrubs, and trees, mowing and edging lawns, and maintaining the overall health and appearance of outdoor spaces.",
      url: "https://raydito.com/jobform"
    },
    
  ];

  
  const[field,setCookiefiels] = useState();
  function valueChange(e){
    e.preventDefault();
    console.log(e.target.value)
  }


  function handleApply(valueSelected){
   
    console.log(valueSelected)
    localStorage.setItem("lastname", "Smith");

  }

    const [inputText, setInputText] = useState("");
  let handleSearch = (e) => {
    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };
  
   var filterBlog = blogPosts.filter((el) => {
    if (inputText === '') {
        return el;
    } else {
        return el.text.toLowerCase().includes(inputText)
    }
})
  return (
    <Container >
      <Content>
        <HeadingInfoContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <HeadingTitle>{heading}</HeadingTitle>
          <HeadingDescription>{description}</HeadingDescription>
        </HeadingInfoContainer>
        {/* <div>
            <input type="search" className="form-control" name="Search" onChange={handleSearch} placeholder="Search"/>
          </div> */}
        <ThreeColumn id="mid">
          
          {blogPosts.map((post, index) => (
            <Column key={index}>
              <Card>
                <Image imageSrc={post.imageSrc} />
                <Details>
                  <MetaContainer>
                    <Meta>
                      <UserIcon />
                      <div>{post.author}</div>
                    </Meta>
                    <Meta>
                      <TagIcon />
                      <div>{post.category}</div>
                    </Meta>
                  </MetaContainer>
                  <Title>{post.title}</Title>
                  <Description>{post.description}</Description>
                  <Links href={post.url} target="_blank">Apply Now</Links>
                 
{/* <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Launch demo modal
</button> */}


<div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-xl">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="exampleModalLabel">Job Description</h1>
        <Close type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">X</Close>
      </div>
      <div className="modal-body">
      <Title>{post.title}</Title>
      <p>Raydito</p>
      <div>
        
        <pre><p>{
          post.description
          }</p></pre>
          <p>{post.title}</p>

      </div>
      </div>
      <div className="modal-footer">
        <Back type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</Back>
        
        <Links type="button" className="btn btn-primary" onClick={handleApply(post.title)} href="/jobform" >Apply</Links>
       
      </div>
    </div>
  </div>
</div>
                </Details>
              </Card>
            </Column>
          ))}
        </ThreeColumn>
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
