import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {css} from "styled-components/macro"; //eslint-disable-line
import { SectionHeading as HeadingTitle, Subheading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as UserIcon } from "feather-icons/dist/icons/user.svg";
import { ReactComponent as TagIcon } from "feather-icons/dist/icons/tag.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-3.svg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const ThreeColumn = tw.div`flex flex-col items-center lg:items-stretch lg:flex-row flex-wrap`;
const Column = tw.div`mt-24 lg:w-1/3`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Card = tw.div`lg:mx-4 xl:mx-8 max-w-sm flex flex-col h-full`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-cover bg-center h-80 lg:h-64 rounded rounded-b-none`
]);

const Details = tw.div`p-6 rounded border-2 border-t-0 rounded-t-none border-dashed border-primary-100 flex-1 flex flex-col items-center text-center lg:block lg:text-left`;
const MetaContainer = tw.div`flex items-center`;
const Meta = styled.div`
  ${tw`text-secondary-100 font-medium text-sm flex items-center leading-none mr-6 last:mr-0`}
  svg {
    ${tw`w-4 h-4 mr-1`}
  }
`;

const Title = tw.h5`mt-4 leading-snug font-bold text-lg`;
const Description = tw.p`mt-2 text-sm text-secondary-100`;
const Link = styled(PrimaryButtonBase).attrs({as: "a"})`
  ${tw`inline-block mt-4 text-sm font-semibold`}
`

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

export default ({

  subheading = "Jobs",
  heading = <><span tw="text-ray-500">We</span> <span tw="text-orange-500">Hire.</span></>,

  description = "Opportunities for great career.",

}) => {
  const blogPosts = [
    {
      imageSrc:
        "https://images.unsplash.com/photo-1581578731548-c64695cc6952?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8Y2xlYW5pbmd8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",


      author: "Pune,Maharashtra",
      category: "Any",
      title: "Housekeeping Personal",
      description: "A Housekeeping Person is responsible for ensuring the cleanliness, orderliness, and overall upkeep of indoor spaces such as homes, hotels, offices, and other establishments. The primary responsibilities of a Housekeeping Person include cleaning rooms, making beds, dusting, and other tasks.",

      url: "https://raydito.com/jobform"
    },
    {
      imageSrc:
        "https://plus.unsplash.com/premium_photo-1661899762905-a1f762142ddc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDl8fG1hY2hpbmUlMjB1c2luZ3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",

      
      author: "Pune, Maharashtra",
      category: "Technical",
      title: "Machine Handling",
      description: "A machine handler is responsible for operating, maintaining, and repairing machinery used in manufacturing or production processes. This job involves setting up machines, monitoring their performance, and making adjustments to ensure that they are operating at maximum efficiency.",

      url: "https://raydito.com/jobform"
    },
    {
      imageSrc:
        "https://www.ziprecruiter.com/svc/fotomat/public-ziprecruiter/cms/838527584CNCOperator.jpg",

     
      author: "Remote",
      category: "Technical/Non-Technical",
      title: "CNC/VMC Operator",
      description: "We are looking for an experienced CNC/VMC Operator to join our team. The ideal candidate will be responsible for setting up and operating CNC/VMC machines to produce high-quality parts and components for our manufacturing operations,some basic understanding of machines.",

      url: "https://raydito.com/jobform"
    }
  ];
  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          {subheading && <Subheading>{subheading}</Subheading>}
          <HeadingTitle>{heading}</HeadingTitle>
          <HeadingDescription>{description}</HeadingDescription>
        </HeadingInfoContainer>
        <ThreeColumn>
          {blogPosts.map((post, index) => (
            <Column key={index}>
              <Card>
                <Image imageSrc={post.imageSrc} />
                <Details>
                  <MetaContainer>
                    <Meta>
                      <UserIcon />
                      <div>{post.author}</div>
                    </Meta>
                    <Meta>
                      <TagIcon />
                      <div>{post.category}</div>
                    </Meta>
                  </MetaContainer>
                  <Title>{post.title}</Title>
                  <Description>{post.description}</Description>
                  <Link href={post.url} target="_blank">Apply</Link>
                </Details>
              </Card>
              
            </Column>
            
           
          ))}
        </ThreeColumn>
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
