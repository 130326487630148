import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, Content2Xl } from "components/misc/Layouts";
import tw from "twin.macro";
// eslint-disable-next-line
import { css } from "styled-components/macro";

import GitHubButton from "react-github-btn";

import { LogoLink } from "components/headers/light.js";
import { SectionHeading as HeadingBase } from "components/misc/Headings";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";

import logo from "images/01.png";

import styled from "styled-components";

import { ReactComponent as FacebookIcon } from "../../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../../images/youtube-icon.svg";
import { ReactComponent as Whatsapp } from "../../../images/whatsapp.svg";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";


/* Hero */
const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between`;
const NavLink = tw.a`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 lg:mr-12 last:mr-0 text-gray-700 border-gray-400 hocus:border-gray-700 `;
const PrimaryNavLink = tw(
  NavLink
)`text-gray-100 bg-primary-500 px-6 py-3 border-none rounded hocus:bg-primary-900 focus:shadow-outline`;
const HeroRow = tw(Row)`max-w-xl flex-col justify-between items-center py-20 lg:py-24 mx-auto`;

const Heading = tw(HeadingBase)`text-center text-primary-500 leading-snug`;
const Description = tw(DescriptionBase)`mt-4 text-center lg:text-base text-gray-700 max-w-lg mx-auto lg:mx-0`;


const SocialLinkssContainer = tw.div`mt-10`;
const SocialLinks = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

export default () => {
  /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script insid
   * e public/index.html if you dont need google analytics
   */
  window.gtag("js", new Date());
  window.gtag("config", "UA-45799926-9");

  const downloadUrl = "/Raydito-ui.zip"
  const [counter, setCounter] = React.useState(5);

  
const navigate = useNavigate();
useEffect(() => {
  setTimeout(()=>{
    navigate("/");

  },15000)

 
}, [])

  return (
    <AnimationRevealPage disabled>
      <Container tw="-mx-8 -mt-8 pt-8 px-8 bg-blue-300">
       
        <Content2Xl>
       
          {/* <NavRow>
            <LogoLink href="/">
              <img src={logo} alt="" />
              Raydito

            </LogoLink>
            <div tw="flex flex-col lg:flex-row items-center">
              <NavLink target="_blank" href="https://owaiskhan.me/post/free-tailwindcss-react-ui-kit">
                License & Usage
              </NavLink>
              <NavLink target="_blank" href="https://twitter.com/owaiswiz">
                Twitter
              </NavLink>
              <NavLink target="_blank" href="https://owaiskhan.me">
                Who Am I ?
              </NavLink>
              <PrimaryNavLink target="_blank" href="https://gum.co/QaruQ">
                Download Now
              </PrimaryNavLink>
            </div>
          </NavRow> */}
          <HeroRow>
          <div tw="w-32">
      <img src={logo} alt="" />
      </div>
            <Heading>Thank You!</Heading>
            <div tw="mt-12 text-center ">
            for reaching out to Raydito Services Company. <span tw="font-bold">We appreciate your interest in our jobs we will try to connect as soon as possible.</span>.
            </div>
            <div tw="mt-12 text-center">
              If you liked Raydito and would like to get updates.
            <h3 tw="font-bold">Join US</h3>
              <SocialLinkssContainer>
              <SocialLinks href="https://chat.whatsapp.com/Ij7FbdjWsdnC652kmqHumr">
              <Whatsapp />
            </SocialLinks>

            <SocialLinks href="https://www.facebook.com/rayditoservices/">
              <FacebookIcon />
            </SocialLinks>
            <SocialLinks href="https://twitter.com/RayditoServices">
              <TwitterIcon />
            </SocialLinks>
            <SocialLinks href="https://www.youtube.com/@RayditoServices">
              <YoutubeIcon />
            </SocialLinks>
            
          </SocialLinkssContainer>
         
            </div>
          </HeroRow>
        </Content2Xl>
      </Container>
    </AnimationRevealPage>
  );
};
