import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import "./NavStyle.css";
import Header, { NavLink, NavLinks, PrimaryLink, LogoLink, NavToggle, DesktopNavLinks, MobileNavLinksContainer } from "../headers/light.js";

import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";


const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-white text-base hover:border-gray-300 hover:text-gray-500`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover drop-shadow-xl`}
  background-image: url("https://www.ed.tum.de/fileadmin/w00byg/ed/picture/2022_07_27tum0788_2_3x2.jpg");
  background-repeat: no-repeat;
  background-size: auto;
  `;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-50`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto drop-shadow-xl`;
const TwoColumn = tw.div`pt-10 pb-16 px-4 flex justify-between items-center flex-col lg:flex-row drop-shadow-xl`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-sm text-center lg:text-left sm:text-sm lg:text-xl xl:text-4xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
  #top{
    ${tw`text-ray-500`}
  }
`;

const SlantedBackground = styled.span`

  ${tw`relative text-primary-500 px-4 -mx-4 py-2`}

  ${tw`relative text-orange-500 px-4 -mx-4 py-2`}

  &::before {
    content: "";
    ${tw`absolute inset-0  transform -skew-x-6 -z-10`}
  }
`;

const Notification = tw.span`inline-block my-4 pl-3 py-1 text-blue-100 border-l-4 border-blue-500 font-medium text-sm`;
const HomePrimaryAction = tw.button`px-8 py-3  text-sm sm:text-base sm:px-8 sm:py-4 bg-primary-500 text-gray-500 font-bold rounded-4xl shadow transition duration-300 hocus:bg-primary-200 hocus:text-gray-100 focus:shadow-outline`;

const PrimaryAction = tw.button`px-2 py-3 text-sm sm:text-base sm:mt-4 sm:px-8 sm:py-4 bg-gray-100 text-primary-500 font-bold rounded-4xl shadow transition duration-300 hocus:bg-primary-500 hocus:text-gray-100 focus:shadow-outline`;
const Candidate = tw.button`px-8 py-3  text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-orange-500 text-gray-100 font-bold rounded-4xl shadow transition duration-300 hocus:bg-orange-800 hocus:text-gray-100 focus:shadow-outline`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;

  ${tw`rounded`}import { MainFeature } from 'components/features/TwoColWithButton.js';

  iframe {
    ${tw`rounded bg-purple-500 shadow-xl`}

  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}

  }
`;


export const Inner = tw.nav`
  bg-ray-100 p-2
`

export default () => {
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="#">
        <Link to={"/"}>Home</Link>
      </NavLink>
      <NavLink>
        <Link to={"/about"}>About</Link>
      </NavLink>
      <NavLink>
      
        <Link to={"/services"} className="dropdown-toggle mt-2" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">Services</Link>
        
<ul className="dropdown-menu opacity-75">
  <li><Link className="dropdown-item drops" to="/services/home">Home Services</Link></li>
  <li><Link className="dropdown-item drops" to="/services/industry">Industry Services</Link></li>
  <li><a className="dropdown-item drops" href="https://raydito.com/training">Training</a></li>
</ul>
      </NavLink>
      <NavLink>
        <Link to={"/jobs"}>Jobs</Link>
      </NavLink>
      <NavLink>
        <Link to={"/contact"}>Contac US</Link>

      </NavLink>
    </NavLinks>
    ,
    <NavLinks key={2}>
      <PrimaryLink href="https://raydito.com/jobform">
        Job Application
      </PrimaryLink>
    </NavLinks>
  ];

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
       
        <TwoColumn>
          <LeftColumn>
            <Notification>We Enrich your experience</Notification>
            <Heading>
           
            <SlantedBackground><span id="top">Be a Success </span></SlantedBackground>
              <br />
              <SlantedBackground> Partner.</SlantedBackground>
            </Heading>
            <HomePrimaryAction><a href="https://raydito.com/training" target="_blank">Placement Training</a></HomePrimaryAction>&nbsp;&nbsp;&nbsp;
            <Link to={"/services/industry"}><PrimaryAction>Industry Services</PrimaryAction></Link>&nbsp;&nbsp;&nbsp;
            <Link to={"/jobs"}><Candidate>Jobs</Candidate></Link>

          </LeftColumn>
          <RightColumn>
            {/* <StyledResponsiveVideoEmbed
              url="//player.vimeo.com/video/374265101?title=0&portrait=0&byline=0&autoplay=0&responsive=1"
              background="transparent"
            /> */}
          </RightColumn>
        </TwoColumn>
      </HeroContainer>
    </Container>
  );
};
