import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImageWithCenteredContent.js";
import Features from "components/features/VerticalWithAlternateImageAndText.js";
import Blog from "components/blogs/ThreeColSimpleWithImage.js";
import Testimonial from "components/testimonials/TwoColumnWithImage.js";
import ContactUsForm from "components/forms/SimpleContactUs.js";
import Footer from "components/footers/SimpleFiveColumn.js";
import AboutHead from "./AboutHead";
import AboutCenter from "./AboutCenter"
import tw from "twin.macro";
import { useEffect } from "react";

const Container = tw.div`bg-orange-200`

export default () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  return () => {
  }
}, []);

  return(
  <AnimationRevealPage>
    <Container>
    <AboutHead/>
    <AboutCenter/>
    </Container>
      </AnimationRevealPage>
)};
