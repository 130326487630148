import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/hero/BackgroundAsImage.js";
import Features from "components/features/DashedBorderSixFeatures";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";
import MainFeature2 from "components/features/TwoColWithTwoFeaturesAndButtons.js";
import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
import Blog from "components/blogs/ThreeColSimpleWithImageAndDashedBorder.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import FAQ from "components/faqs/SimpleWithSideImage.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import customerSupportIllustrationSrc from "images/questions.jpg";
import Carosaul from "components/rayditopages/Carosaul/Carosaul";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default ()=>{

 
return (
  <>
<AnimationRevealPage>
<Hero/>
    <MainFeature />  
  <Carosaul/>
    <Features />
    <Blog />
    <MainFeature2 />
    <Portfolio />

    {/* <Portfolio /> */}

    <Testimonial
      subheading="Testimonials"
      heading={
        <>
          <span tw="text-ray-500">Our Clients</span> <span tw="text-orange-500">Love Us.</span>

        </>
      }
      description="Overall, Our client Love Us is a company that truly cares about its customers and is committed to delivering outstanding results With their dedication to customer satisfaction, it's no surprise that their clients love them and continue to work with them time and time again."
      testimonials={[
        {
          imageSrc:
            "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
          profileImageSrc:
            "https://images.unsplash.com/photo-1435348773030-a1d74f568bc2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NDR8fG1hbiUyMGZhY2UlMjBzaGFkb3clMjBkYXJrJTIwZmFjZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
          quote:
            "Success is not just about achieving your goals, but about inspiring others to achieve theirs as well",
          customerName: "Kalim Pathan",
          customerTitle: "CEO, Raydito."
        },
        {
          imageSrc:
            "https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80",
          profileImageSrc:
            "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
          quote:
            "The Best Available HR Services in Business Right Now !",
          customerName: "Unknown",
          customerTitle: "Investor"
        }
      ]}
      textOnLeft={true}
    />
    <FAQ id="faq"
      imageSrc={customerSupportIllustrationSrc}
      imageContain={true}
      imageShadow={false}
      subheading="FAQs"
      heading={
        <>
         <span tw="text-ray-500"> Do you have </span><span tw="text-orange-500">Questions ?</span>
        </>
      }
    />
    
    <ContactUsForm />
    <Footer />
    </AnimationRevealPage>
    </>
);
    }