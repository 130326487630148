import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import {ReactComponent as SvgDotPatternIcon} from "../../../images/dot-pattern.svg"
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const FormContainer = styled.div`
  ${tw`p-10 sm:p-12 md:p-16 bg-gray-300 text-gray-100 rounded-lg relative`}
  form {
    ${tw`mt-4`}
  }
  h2 {
    ${tw`text-3xl sm:text-4xl font-bold`}
  }
  input,textarea {
    ${tw`w-full bg-transparent text-gray-100 text-base font-medium tracking-wide border-b-2 py-2 text-gray-100 hocus:border-pink-400 focus:outline-none transition duration-200`};

    ::placeholder {
      ${tw`text-gray-500`}
    }
  }
`;

const TwoColumn = tw.div`flex flex-col sm:flex-row justify-between`;
const Column = tw.div`sm:w-5/12 flex flex-col`;
const InputContainer = tw.div`relative py-5 mt-6`;
const Label = tw.label`absolute top-0 left-0 tracking-wide font-semibold text-sm`;
const Input = tw.input``;
const option = tw.option``;
const select = tw.select``;
const TextArea = tw.textarea`h-24 sm:h-full resize-none`;
const FileUpload = tw.textarea`h-24 sm:h-full resize-none`;
const SubmitButton = tw.button`w-full sm:w-32 mt-6 py-3 bg-gray-100 text-primary-500 rounded-full font-bold tracking-wide shadow-lg uppercase text-sm transition duration-300 transform focus:outline-none focus:shadow-outline hover:bg-gray-300 hover:text-primary-700 hocus:-translate-y-px hocus:shadow-xl`;

const SvgDotPattern1 = tw(SvgDotPatternIcon)`absolute bottom-0 right-0 transform translate-y-1/2 translate-x-1/2 -z-10 opacity-50 text-primary-500 fill-current w-24`

export default () => {

    const[emp_name,setEmp_name] = useState();
    const[emp_email,setEmp_email] = useState();
    const[emp_contact,setEmp_contact] = useState();

    const[emp_job,setEmp_job] = useState();
    const[emp_qualification,setEmp_qualification] = useState();
    
    const[emp,setEmp] = useState({eid:"",emp_email:"",emp_name:"",emp_contact:""});
    const handleChanges=(event)=>{
        event.preventDefault();
//         const { nname, value } = event.target;
//         const ename = event.target.name
         
//   setEmp({
//     ...emp,ename:event.target.value
//   });
setEmp_name(event.target.value);
  console.log(emp_name)
    }
    const handleEmail=(e)=>{
        setEmp_email(e.target.value);

    }
    const handleContact=(e)=>{
        setEmp_contact(e.target.value);
    }
    const handleJob=(e)=>{
        setEmp_job(emp_contact.target.value);
    }
    const handleQualificaion=(e)=>{
        setEmp_qualification(e.target.value);
    }

    const handleSubmit=async ()=>{

        console.log(emp_email+""+emp_name)

        const data = {
            emp_id:"100",
            emp_name:emp_name,
            emp_email:emp_email,
            emp_contact:emp_contact
        }

       await axios.post('https://sheet.best/api/sheets/09b8e091-a7cb-42d0-be0b-b49d94427fce',data).then(response=>{
       console.log(response);
      setEmp_name('');
      setEmp_email('');
      setEmp_contact('');
      
    })
    
//        await fetch("https://api.apispreadsheets.com/data/h5XjJyGukhlRq1vH/", {
// 	method: "POST",
// 	body: JSON.stringify({"data": {"emp_id":"10","email_id":emp_email,"emp_name":emp_name,"contact_no":emp_contact}}),
// }).then(res =>{
// 	if (res.status === 201){
// 		// SUCCESS
// 	}
// 	else{
// 		// ERROR
// 	}
// })
    }

    useEffect(() => {
      const script = document.createElement('script');
      script.src="https://js.hsforms.net/forms/embed/v2.js";
      document.body.appendChild(script);
  
      script.addEventListener('load', () => {
          // @TS-ignore
          if (window.hbspt) {
              // @TS-ignore
              window.hbspt.forms.create({
                   region: "na1",
                   portalId: "24354657",
                   formId: "dd0264de-1078-4951-8a8f-118bbf146a7d",
                  target: '#hubspotForm'
              })
          }
      });
  }, [1]);

//   <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/embed/v2.js"></script>
// <script>
//   hbspt.forms.create({
//     region: "na1",
//     portalId: "24354657",
//     formId: "dd0264de-1078-4951-8a8f-118bbf146a7d"
//   });
// </script>


  return (
    <Container>
      <Content>
        <FormContainer>
          <div tw="mx-auto max-w-4xl p-4 text-black">
            <h2>Apply for Job</h2>
            <form action="#" className="p-4">
              {/* <TwoColumn>
                <Column>
                  <InputContainer>
                    <Label htmlFor="name-input">Your Full Name</Label>
                    <Input id="name-input" type="text" name="emp_name" value={emp_name} onChange={handleChanges} placeholder="E.g. John Doe" />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Your Email Address</Label>
                    <Input id="email-input" type="email" name="emp_email" value={emp_email} onChange={handleEmail} placeholder="E.g. john@mail.com" />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="email-input">Contact No.</Label>
                    <Input type="tel" id="phone" name="phone" value={emp_contact} onChange={handleContact} placeholder="E.g. 999999999" />
                  </InputContainer>
                  <InputContainer>
                    <Label htmlFor="name-input">Your Full Name</Label>
<select id="country-state" className="text-black form-control" name="country-state">
    <option value="">Select state</option>
    <option value="AN">Andaman and Nicobar Islands</option>
    <option value="AP">Andhra Pradesh</option>
    <option value="AR">Arunachal Pradesh</option>
    <option value="AS">Assam</option>
    <option value="BR">Bihar</option>
    <option value="CH">Chandigarh</option>
    <option value="CT">Chhattisgarh</option>
    <option value="DN">Dadra and Nagar Haveli</option>
    <option value="DD">Daman and Diu</option>
    <option value="DL">Delhi</option>
    <option value="GA">Goa</option>
    <option value="GJ">Gujarat</option>
    <option value="HR">Haryana</option>
    <option value="HP">Himachal Pradesh</option>
    <option value="JK">Jammu and Kashmir</option>
    <option value="JH">Jharkhand</option>
    <option value="KA">Karnataka</option>
    <option value="KL">Kerala</option>
    <option value="LA">Ladakh</option>
    <option value="LD">Lakshadweep</option>
    <option value="MP">Madhya Pradesh</option>
    <option value="MH">Maharashtra</option>
    <option value="MN">Manipur</option>
    <option value="ML">Meghalaya</option>
    <option value="MZ">Mizoram</option>
    <option value="NL">Nagaland</option>
    <option value="OR">Odisha</option>
    <option value="PY">Puducherry</option>
    <option value="PB">Punjab</option>
    <option value="RJ">Rajasthan</option>
    <option value="SK">Sikkim</option>
    <option value="TN">Tamil Nadu</option>
    <option value="TG">Telangana</option>
    <option value="TR">Tripura</option>
    <option value="UP">Uttar Pradesh</option>
    <option value="UT">Uttarakhand</option>
    <option value="WB">West Bengal</option>
</select>
                  </InputContainer>

                 
                </Column>
                <Column>
                  <InputContainer tw="flex-1">
                    <Label htmlFor="name-input">Upload Resume</Label>
                    <Input id="name-input" type="file" className="custom-file-input" name="name" placeholder="E.g. John Doe" />
                  </InputContainer>
                </Column>

              </TwoColumn> */}

              
              {/* <SubmitButton onClick={handleSubmit}>Submit</SubmitButton> */}
              <div id="hubspotForm" tw="text-white">
                <span tw="pt-3"></span>
              </div>
             
            </form>
          </div>
          <SvgDotPattern1 />
        </FormContainer>
      </Content>
    </Container>
  );
};
