import React from 'react'
import "../Carosaul/Carosaul.css"
import carosel from "../../../images/carosel.jpg"

import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { useEffect } from 'react';

export default function Carosaul() {

  const Heading = tw(
    SectionHeading
  )`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;



 


{/* <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/embed/v2.js"></script>
<script>
  hbspt.forms.create({
   
  });
</script> */}

  return (

     <div className='carosaul-div'>
<div className='p-4'>
  {/* <h1 className='fs-2 fw-bold'>Working Profesional</h1> */}
  <Heading><span id="top">Working Profesionals </span></Heading>
  </div>
      
<div id="carouselExampleInterval" data-bs-ride="carousel" className="carousel slide ">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleInterval" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div className="carousel-inner"  id='car'>
    <div className="carousel-item active" data-bs-interval="3000">
      <img src="https://cdni.autocarindia.com/utils/imageresizer.ashx?n=http://img.haymarketsac.in/autocarpro/IMG/573/69573/dsc-0111.JPG&w=750&h=490&q=75&c=1" className="d-block w-100 img-car" alt="..."/>
      <div className="carousel-caption d-none d-md-block">
        <h5>ManPower</h5>
        <p>We Provide All types of Manpower as per Requirement from Technical to Non-Technical.</p>
      </div>
    </div>
    <div className="carousel-item" data-bs-interval="3000">
      <img src="https://imttcanada.com/wp-content/uploads/2014/11/imtt-cnc.jpg" className="d-block w-100 img-car" alt="..."/>
      <div className="carousel-caption d-none d-md-block">
        <h5>CNC/VMC Operator</h5>
        <p>A CNC (Computer Numerical Control) operator works with computer-controlled machinery to produce precision parts and components.</p>
      </div>
    </div>
    <div className="carousel-item" data-bs-interval="3000">
      <img src="https://s3.youthkiawaaz.com/wp-content/uploads/2014/07/06094511/engineering-students.jpg" className="d-block w-100 img-car" alt="..."/>
      <div className="carousel-caption d-none d-md-block">
        <h5>Engineer</h5>
        <p>An engineer working with machines designs, builds, and maintains systems that utilize technology to achieve specific goals, such as increasing efficiency or solving complex problems.</p>
      </div>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>



</div>
</div>


  )
}
