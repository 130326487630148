import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/BackgroundAsImage";
// import Features from "components/features/VerticalWithAlternateImageAndText.js";
import Blog from "components/blogs/ThreeColSimpleWithImage.js";
import Testimonial from "components/testimonials/TwoColumnWithImage.js";
import ContactUsForm from "components/forms/SimpleContactUs.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import TrendingCard from "components/cards/TwoTrendingPreviewCardsWithImage.js";
import Features from "components/features/ThreeColSimple.js";
import SliderCard from "components/cards/ThreeColSlider.js";
import JobCard from "../rayditopages/JobsComponent/JobCards"
import JobHero from "../rayditopages/JobsComponent/JobHero"
import MoreJobs from "./JobsComponent/MoreJobs";
import JobContent from "./JobsComponent/JobContent";

export default () => (
  <AnimationRevealPage>
    <JobHero />
    <JobCard />
    <Footer />
  </AnimationRevealPage>
);
